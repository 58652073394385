<!-- @Author: Yu_Bo 2023-09-05 18:28:44-->
<template>
  <div class="captions_vue">
    <!-- 标题 -->
    <div class="vue_title">字幕管理</div>
    <!--  -->
    <div class="captions_main">
      <div class="main_condition">
        <div class="cond_left">
          <el-button type="primary" class="btnBgColor_blue_empty" @click="addCaptions" size="small"
            icon="el-icon-plus">添加字幕</el-button>
          <el-input v-model="name" size="small" @keyup.enter.native="reset" @clear="reset" :clearable="true"
            placeholder="搜索字幕名称"></el-input>
          <el-select v-model="classify" size="small" @change="reset" :clearable="true" placeholder="请选择分类">
            <el-option v-for="item in classifyOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="status" size="small" @change="reset" :clearable="true" placeholder="请选择状态">
            <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <!--  -->
      <div class="main_table">
        <el-table :data="list" v-loading="loading" style="width: 100%" header-cell-class-name="table-header"
          cell-class-name="cell-class">
          <template slot="empty">
            <empty-data img="no_img_b.png" text="暂无数据"></empty-data>
          </template>
          <el-table-column prop="id" label="序号"></el-table-column>
          <el-table-column prop="name" label="字幕名称"></el-table-column>
          <el-table-column label="字幕缩略图">
            <template slot-scope="scope">
              <!-- <img :src="scope.row.thumbnail" alt="" class="tablepic"> -->
              <el-image style="width: 50px;" :src="scope.row?.thumbnail" :preview-src-list="[scope.row?.thumbnail]"
                fit="contain"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="分类">
            <template slot-scope="scope">
              <span>{{ scope.row?.materialCategory?.name || "--" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.status" :active-value="1" :inactive-value="2"
                @change="chnageStatus($event, scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="添加时间"></el-table-column>
          <el-table-column class-name="last_class" label="操作" fixed="right" width="100">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="editCaptions(scope.row)">编辑</el-button>
              <el-button type="text" size="small" @click="delCaptions(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="page_box" v-if="total" style="padding-bottom: 20px">
        <paging-page :total="total" align="right" @sizeChange="sizeChangeBtn"
          @currentChange="currentChangeBtn"></paging-page>
      </div>
    </div>
    <!-- 删除提示 -->
    <point-out ref="dialogTip" dialogTitle="提示" type="material" @close="closeCaptions" dialogWidth="420px"
      :iconShow="true" iconColor="#FF7432" borderRadius="10px" :cancelShow="true" :affirmShow="true">
      <div class="zc_dialog_tip_content">
        <div class="text">请确认是否删除字幕，删除后将不可恢复</div>
      </div>
    </point-out>
  </div>
</template>

<script>
import PointOut from '@/components/point_out/point_out.vue' // 提示
import EmptyData from '@/components/empty_data/empty_data.vue' // 空数据
import PagingPage from '@/components/paging_page/paging_page.vue' // 分页
export default {
  components: { PointOut, EmptyData, PagingPage },
  props: {},
  data() {
    return {
      // 名称
      name: '',
      // 分类
      classify: '',
      classifyOptions: [],
      // 状态
      status: '',
      statusOptions: [
        { value: 1, label: '开启' },
        { value: 2, label: '关闭' }
      ],
      //
      list: [],
      loading: false,
      //
      page: 1,
      limit: 10,
      total: 0,
      //
      delIndex: null
    }
  },

  computed: {
    searchForm() {
      return {
        page: this.page,
        limit: this.limit,
        name: this.name,
        category_id: this.classify,
        status: this.status
      }
    }
  },
  mounted() {
    this.getclassify()
    this.getList()
  },
  methods: {
    reset() {
      this.page = 1
      this.total = 0
      this.getList()
    },
    // 获取分类的列表
    getclassify() {
      this.$materialApi.material_caption_cat().then(res => {
        this.classifyOptions = res?.result?.cat_list ?? []
      })
    },
    // 数据列表
    getList() {
      this.loading = true
      this.$materialApi.material_caption_list({
        ...this.searchForm
      }).then(res => {
        this.list = res?.result?.data ?? []
        this.total = res?.result?.total ?? 0
        this.page = res?.result?.current_page ?? 0
        this.loading = false
        if (res.code !== 1000) {
          this.$message.error(res.message)
        }
      })
    },
    // 添加
    addCaptions() {
      //   this.$refs.addEdit.openDialog()
      this.$router.push({
        path: '/material/captions/add_edit'
      })
    },
    // 编辑
    editCaptions(item) {
      // this.$refs.addEdit.openDialog(item)
      this.$router.push({
        path: '/material/captions/add_edit',
        query: {
          item: JSON.stringify(item)
        }
      })
    },
    // 删除
    delCaptions(id) {
      this.delId = id
      this.$refs.dialogTip.openDialogBtn()
    },
    // 确定删除
    closeCaptions() {
      this.$materialApi.material_caption_del(this.delId).then(res => {
        this.$message({
          message: res.message,
          type: res.code === 1000 ? 'success' : 'error'
        })
        if (res.code === 1000) {
          if (this.list.length == 1) {
            this.reset()
          } else {
            this.getList()
          }
        }
      })
    },
    // 改变每页条数
    sizeChangeBtn(val) {
      this.limit = val
      this.page = 1
      this.getList()
    },
    // 改变页数
    currentChangeBtn(val) {
      this.page = val
      this.getList()
    },
    // 更改状态
    chnageStatus(status, item) {
      this.$materialApi.material_caption_status({
        id: item.id,
        status: status
      }).then(res => {
        this.$message({
          message: res.message,
          type: res.code === 1000 ? 'success' : 'error'
        })
        if (res.code !== 1000) {
          item.status = item.status === 1 ? 2 : 1
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.captions_vue {
  width: 100%;

  .captions_main {
    width: 100%;
    padding: 0 20px;

    .main_condition {
      width: 100%;
      padding: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .cond_left {
        display: flex;
        align-items: center;

        .el-button {
          padding: 8px 10px;
          margin-right: 20px;
        }

        .el-select {
          margin-right: 20px;
          width: 160px;
        }

        .el-input {
          margin-right: 20px;
          width: 160px;
        }
      }
    }
  }
}
</style>
